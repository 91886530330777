import * as React from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/layout/layout"

function Success() {
    return (
        <Layout pageTitle="Message Sent">
            <Helmet>
                <meta name="robots" content="noindex" />
            </Helmet>
            <section style={{width: 'min(90%, 800px)', margin: 'auto', textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                <h1>Your message has been sent successfully.</h1>
                <p>I'll get back to you with a reply as soon as possible. In the meantime, you can use the menus to find any more information that you might find helpful.</p>
            </section>
        </Layout>
    )
}

export default Success